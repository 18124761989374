var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"visible":_vm.visible,"title":_vm.title,"closable":false}},[_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":_vm.onCancel}},[_vm._v(" Cancelar ")]),_c('a-button',{key:"submit",staticClass:"btn btn-success",on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.submitLabel)+" ")])],1),_c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"small","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Nombre"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
									'name',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											} ],
									} ]),expression:"[\n\t\t\t\t\t\t\t\t\t'name',\n\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Campo requerido.',\n\t\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-12"},[_c('a-form-item',{attrs:{"label":"Descripción"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['description']),expression:"['description']"}]})],1)],1)])])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }